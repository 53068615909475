import React from 'react';
import {Table, TableContainer, EmployeeEnterprise, AdminEnterprise} from "./styles";

const wellness = [
  'Group and Personal Challenges',
  'Fitness Device Integration',
  'Daily Health Pro Tips',
  'Tailored Wellness Adventures',
  'Personalized Education',
  'Wellness Videos',
  'Targeted Health Resources',
  'Health and Wellness Webinars',
];
const recognition = [
  'Peer Recognition',
  'Tagging',
  'Comments and Voting',
  'Discussion',
  'Leaderboard',
  'Rewards Program',
];
const intranet = [
  'Employee Directory',
  'Search',
  'Global Social Feed',
  'Mobile Access',
  'Notification and Reminders',
  'Company Events',
];
const data = [
  'Activity data and History',
  'Health Risk Assessment',
  'Engagement Data',
  'Survey Data',
  'Biometric Form Submission',
  'Biometric Data',
];
const support = [
  'Knowledge Base',
  'Email Support Line',
  'Account Management',
];
const wellnessAdmin = [
  'Challenge Management',
  'Wellness Initiative Management',
];
const engagementAdmin = [
  'Engagement dashboard',
  'Segment Participation',
  'Engagement Surveys',
];
const recognitionAdmin = [
  'Employee Recognition',
  'Rewards Program Report',
  'Recognition Activity',
  'Company Analytics',
];
const intranetAdmin = [
  'User Directory',
  'Search',
  'User, Role, Access Management',
  'Search',
  'Event Management',
  'Message Tools',
  'Task Management',
  'Customized Fields',
  'HRIS Integrations',
  'Initiative Management',
  'Customize Initiatives'
];
const dataAdmin = [
  'Annual Reporting',
  'Wellness Data and Analytics',
  'Employee Segmenting',
  'Health Data and Analysis',
  'Biometric Submission Tracking',
  'Biometric Data',
];
const supportAdmin = [
  'Basic Support and Knowledge Base',
  'Wellness Committee Tools',
  'Account Management',
  'Dedicated Customer Success Manager'
];

const EnterpriseFeatureTable = () => (
  <TableContainer>
    <div className= "tableHeader enterpriseHeader" style={{marginBottom: '30px'}}>
      <div>Employee Features</div>
      <div>Admin Features</div>
    </div>
    <EmployeeEnterprise>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#2296B7'}}>WELLNESS</div>
        {
          wellness.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#71A148'}}>RECOGNITION</div>
        {
          recognition.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#FFA1A1'}}>INTRANET</div>
        {
          intranet.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#E8913B'}}>DATA</div>
        {
          data.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table paddingBottom= {'50px'} setWidth >
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#392AC1'}}>SUPPORT</div>
        {
          support.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
    </EmployeeEnterprise>
    <AdminEnterprise>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#2296B7'}}>WELLNESS</div>
        {
          wellnessAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#E84F3B'}}>ENGAGEMENT</div>
        {
          engagementAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#71A148'}}>RECOGNITION</div>
        {
          recognitionAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#FFA1A1'}}>INTRANET</div>
        {
          intranetAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#E8913B'}}>DATA</div>
        {
          dataAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
      <Table padding setWidth>
        <div className="packageHeadingEnterprise" style={{backgroundColor: '#392AC1'}}>SUPPORT</div>
        {
          supportAdmin.map((item, index) => (
            <div className={`tableRow ${index % 2 !== 0 && 'enterpriseRow'}`} key={index}>
              <div>{item}</div>
              <div><img src="/public/images/PricingV2/tick-blue.png"/></div>
            </div>
          ))
        }
      </Table>
    </AdminEnterprise>
  </TableContainer>
);

export default EnterpriseFeatureTable;