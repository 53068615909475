import React from 'react';
import {DropDownContainer, PosterContainer, ContactButton} from "./styles";
// import FormEnterprise from "./FormContainer";
import EnterpriseFeatureTable from "./EnterpriseFeatureTable";
import PropTypes from "prop-types";
import AddOnSection from './AddOnSection';

const Enterprise = (props) => (
  <div className="teamPackage">
    {/*<EnterpriseContainer>*/}
    {/*<div>Training Amigo Enterprise</div>*/}
    {/*<FormEnterprise*/}
    {/*handleTextChange={props.handleTextChange}*/}
    {/*email={props.email}*/}
    {/*subject={props.subject}*/}
    {/*helpDescription={props.helpDescription}*/}
    {/*handleSubmit={props.handleSubmit}*/}
    {/*disable={props.disable}*/}
    {/*/>*/}
    {/*</EnterpriseContainer>*/}
    <div className="startText">
      Woliba Enterprise empowers teams to better manage people, culture, and wellbeing
      across the entire organization including remote groups.
    </div>
    <div className="contactContainer">
      <ContactButton onClick={() => props.history.push('/contact')}>Contact Our Sales Team</ContactButton>
      <div className="hr"/>
    </div>
    <DropDownContainer>
      <span>Enterprise Features</span>
      <span className="dropIcon"/>
    </DropDownContainer>
    <EnterpriseFeatureTable/>
    <PosterContainer>
      <div>
        <picture>
          <source srcSet="/public/images/PricingV2/Stock.png"/>
          <img src="/public/images/PricingV2/Stock.png"/>
        </picture>
      </div>
      <div>
        <h1>Woliba For Good</h1>
        <p>Woliba wants to support organizations and give back by providing special
          pricing for qualified nonprofit organizations and educational institutions.</p>
        <span onClick={() => props.history.push('/contact')}>Connect to Our Team to Qualify <img
          src="/public/images/PricingV2/right-angle.png"/></span>
      </div>
    </PosterContainer>
    <AddOnSection history={props.history}/>
  </div>
);

Enterprise.propTypes = {
  handleTextChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  helpDescription: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  history: PropTypes.object
};

export default Enterprise;