import React, {Component} from 'react';
import {QuestionCard} from "./styles";
import PropTypes from 'prop-types';

export default class QuestionTile extends Component{
  constructor(){
    super();
    this.state = {
      open: false
    }
  }

  render(){
    const {open} = this.state;
    const {item, index} = this.props;
    return(
      <QuestionCard>
        <div onClick={()=>this.setState({open: !open})}>
          <p>
            {item.question}
          </p>
          <span><i className={`fa fa-caret-down ${open && 'rotateIcon'}`}/></span>
        </div>
        {
          open ?
            <div className="accordion">
              <p>{item.answer} {index === 6 && <span> <a href="mailto:sales@woliba.io"> sales@woliba.io</a></span>}</p>
            </div>
            :
            null
        }
      </QuestionCard>
    )
  }
}

QuestionTile.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};