import React, {Component} from 'react';
import {GetStartedButton, Plan, PlanSection} from "./styles";
import PropTypes from 'prop-types';
import {getAllPlans} from '../../redux/actions';
import {connect} from 'react-redux';
import Waiting from '../Waiting';
const planPayload = [{
  name: 'Starter',
  pack: '4',
  text: 'Per enrolled user, per month billed annually',
  color: '#71A147'
}, {
  name: 'Core',
  pack: '6',
  text: 'Per enrolled user, per month billed annually',
  color: '#1E76AB'
}, {
  name: 'Premium',
  pack: '8',
  text: 'Per enrolled user, per month billed annually',
  color: '#e99139'
}];

class PlanContainer extends Component{
  componentDidMount(){
    const{allPlans, getAllPlans, companyId} = this.props;
    if(!allPlans) getAllPlans(companyId);
  }
  render(){
    const {history, allPlans, showHideCardPopup, billingStatus, selectedPlan, subscriptionStatus, isLoading} = this.props;
    if(isLoading && !allPlans){
      return <Waiting />
    }
    const plans = allPlans;
    return(
      <PlanSection width={billingStatus ? 1 : 0} justify={billingStatus ? 1 : 0} >
        {
          plans && plans.length > 0 && plans.map((item, index) => (
            <Plan width={billingStatus ? 1 : 0} background={planPayload[index].color} key={index} display={billingStatus ? 1 : 0}>
              <div>{item.plan_name}</div>
              {billingStatus && selectedPlan.plan_name === item.plan_name && <div className={'selected'}><div>Current Plan</div></div>}
              <div className="amount"><h1>${parseInt(item.amount)}</h1><span>{item.interval === 'year' ? 'yr' : (item.interval).substring(0, 2)}</span></div>
              <p>{billingStatus ? "per user": planPayload[index].text}</p>
              {(billingStatus ? selectedPlan.plan_name !== item.plan_name : true) && <div className="buttons">
                <GetStartedButton onClick={()=> billingStatus ? showHideCardPopup(item, 0) : history.push('/contact')} borderColor={planPayload[index].color}>
                  {billingStatus ? subscriptionStatus ? 'Select' : 'Update' : "GET STARTED"}
                </GetStartedButton>
              </div>
              }
            </Plan>
          ))
        }
        <hr/>
      </PlanSection>
    )
  }
}

PlanContainer.propTypes = {
  history: PropTypes.object,
  allPlans: PropTypes.array,
  selectedPlan: PropTypes.object,
  showHideCardPopup: PropTypes.func,
  getAllPlans: PropTypes.func,
  billingStatus: PropTypes.bool,
  subscriptionStatus: PropTypes.bool,
  isLoading: PropTypes.bool,
  companyId: PropTypes.number
};

const mapStateToProps = (state) => ({
  isLoading: state.stripeData.isLoading,
  allPlans: state.stripeData.allPlans
});

const mapDispatchToProps = (dispatch) => ({
  getAllPlans: (companyId) => dispatch(getAllPlans(companyId))
});

export default  connect(mapStateToProps, mapDispatchToProps)(PlanContainer);