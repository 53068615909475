import React from 'react';
import PropTypes from "prop-types";
import {AddOnCard, AddOnContainer} from "./styles";
import { Interweave } from 'interweave';


const addOnPayload = [{
  url: '/public/images/PricingV2/support.png',
  name: 'Premium Support',
  description: 'Dedicated customer success manager with 4-hour response time.',
  amount: 'Starting at <span>$500</span> per month',
  button: 'Schedule a Call'
},{
  url: '/public/images/PricingV2/setting.png',
  name: 'Custom Onboarding',
  description: 'Our Success team will design and coordinate a custom onboarding experience for your team.',
  amount: 'Starting at <span>$1500</span>',
  button: 'Get a Quote'
},{
  url: '/public/images/PricingV2/biometric-report.png',
  name: `Biometric Report`,
  description: 'Receive aggregated biometric data in real-time so you can address health concerns faster while staying compliant.',
  amount: '',
  button: 'Schedule a Call'
}];

const AddOnSection = (props) => (
  <AddOnContainer>
    <div>
      <h1>Add-on Service</h1>
      <p>Add any of these additional services to your plan!</p>
    </div>
    <div className="cards">
      {
        addOnPayload.map((item, index) => (
          <AddOnCard key={index}>
            <div>
              <img src={item.url}/>
              <h1>{item.name}</h1>
              <p>{item.description}</p>
              {/* <p dangerouslySetInnerHTML={{ __html: item.amount }} /> */}
              <Interweave content={item.amount} />
            </div>
            <div className='button'>
              <div onClick={() => props.history.push('/contact')}>{item.button}</div>
            </div>
          </AddOnCard>
        ))
      }
    </div>
  </AddOnContainer>
);

AddOnSection.propTypes = {
  history: PropTypes.object
};

export default AddOnSection;